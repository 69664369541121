<template>
  <div
    class="side-content-wrap"
    @mouseenter="isMenuOver = true"
    @mouseleave="isMenuOver = false"
    @touchstart="isMenuOver = true"
    v-if="company.id != superAdmin"
  >
    
    <vue-perfect-scrollbar
      :settings="{ suppressScrollX: true, wheelPropagation: false }"
      :class="{ open: getSideBarToggleProperties.isSideNavOpen }"
      class="sidebar-left rtl-ps-none ps scroll"
    >
      <div>
        <ul class="navigation-left">
          <li
            class="nav-item"
          >
            <router-link class="nav-item-hold" :to="{ name: 'dashboard-index' }">
              <i class="nav-icon i-Bar-Chart"></i>
              <span class="nav-text">Dashboard</span>
            </router-link>

            <div class="triangle"></div>
          </li>
          <li
            class="nav-item"
          >
            <router-link class="nav-item-hold" :to="{ name: 'usuario-index'}">
              <i class="nav-icon i-Administrator"></i>
              <span class="nav-text">Usuarios</span>
            </router-link>

            <div class="triangle"></div>
          </li>
          <li
            class="nav-item"
          >
            <router-link class="nav-item-hold" :to="{ name: 'facturacion-index'}">
              <i style="font-size:25px;" class="i-Receipt-3"></i>
              <span class="nav-text">Facturacion</span>
            </router-link>

            <div class="triangle"></div>
          </li>
          <li
            class="nav-item"
          >
            <router-link class="nav-item-hold" :to="{ name: 'datos_empresa-index'}">
              <i style="font-size:25px;" class="i-Files"></i>
              <span class="nav-text">Datos de Empresa</span>
            </router-link>

            <div class="triangle"></div>
          </li>
          <li
            class="nav-item"
          >
            <router-link class="nav-item-hold" to="/cliente">
              <i style="font-size:25px;" class="i-Add-UserStar"></i>
              <span class="nav-text">Clientes</span>
            </router-link>

            <div class="triangle"></div>
          </li>
          <li
            class="nav-item"
          >
            <router-link class="nav-item-hold" to="/sincronizacion_siat">
              <i style="font-size:25px;" class="nav-icon i-Reload"></i>
              <span class="nav-text">Sincronizacion SIAT</span>
            </router-link>

            <div class="triangle"></div>
          </li>
        </ul>
      </div>
    </vue-perfect-scrollbar>
    <div
      @click="removeOverlay()"
      class="sidebar-overlay"
      :class="{ open: getSideBarToggleProperties.isSecondarySideNavOpen }"
    ></div>
  </div>
  <!--=============== Left side End ================-->
</template>

<script>

import { isMobile } from "mobile-device-detect";
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  components: {
  },

  data() {
    return {
      isDisplay: true,
      isMenuOver: false,
      isStyle: true,
      selectedParentMenu: "",
      isMobile
    };
  },
  mounted() {
    // this.toggleSelectedParentMenu();
    // window.addEventListener("resize", this.handleWindowResize);
    // document.addEventListener("click", this.returnSelectedParentMenu);
    // this.handleWindowResize();
  },

  beforeDestroy() {
    document.removeEventListener("click", this.returnSelectedParentMenu);
    window.removeEventListener("resize", this.handleWindowResize);
  },
  computed: {
    ...mapGetters(["getSideBarToggleProperties"]),
    ...mapState('main', [
      'company',
      'superAdmin'
    ]),
  },

  methods: {
    ...mapActions([
      "changeSecondarySidebarProperties",
      "changeSecondarySidebarPropertiesViaMenuItem",
      "changeSecondarySidebarPropertiesViaOverlay",
      "changeSidebarProperties"
    ]),

    handleWindowResize() {
      //  console.log('not working is Mobile');
      if (window.innerWidth <= 1200) {
        if (this.getSideBarToggleProperties.isSideNavOpen) {
          this.changeSidebarProperties();
        }
        if (this.getSideBarToggleProperties.isSecondarySideNavOpen) {
          this.changeSecondarySidebarProperties();
        }
      } else {
        if (!this.getSideBarToggleProperties.isSideNavOpen) {
          this.changeSidebarProperties();
        }
      }
    },
    toggleSelectedParentMenu() {
      const currentParentUrl = this.$route.path
        .split("/")
        .filter(x => x !== "")[1];

      if (currentParentUrl !== undefined || currentParentUrl !== null) {
        this.selectedParentMenu = currentParentUrl.toLowerCase();
      } else {
        this.selectedParentMenu = "dashboards";
      }
    },
    toggleSubMenu(e) {
      let hasSubmenu = e.target.dataset.submenu;
      let parent = e.target.dataset.item;
      if (hasSubmenu) {
        this.selectedParentMenu = parent;

        this.changeSecondarySidebarPropertiesViaMenuItem(true);
      } else {
        this.selectedParentMenu = parent;
        this.changeSecondarySidebarPropertiesViaMenuItem(false);
      }
    },

    removeOverlay() {
      this.changeSecondarySidebarPropertiesViaOverlay();
      if (window.innerWidth <= 1200) {
        this.changeSidebarProperties();
      }
      // this.toggleSelectedParentMenu();
    },
    returnSelectedParentMenu() {
      if (!this.isMenuOver) {
        // this.toggleSelectedParentMenu();
      }
    },

    toggleSidebarDropdwon(event) {
      let dropdownMenus = this.$el.querySelectorAll(".dropdown-sidemenu.open");

      event.currentTarget.classList.toggle("open");

      dropdownMenus.forEach(dropdown => {
        dropdown.classList.remove("open");
      });
    }
  }
};
</script>

<style lang="" scoped>
</style>
