<template>
  <div class="main-header">
    <div class="logo">
      <img src="@/assets/images/logo-gemgloo.png" alt />
    </div>

    <div @click="sideBarToggle" class="menu-toggle" v-if="company.id != superAdmin">
      <div></div>
      <div></div>
      <div></div>
    </div>

    <div v-if="company.id == superAdmin" class="d-flex align-items-center">
      <!-- Mega menu -->
      <div
        :class="{ show: isMegaMenuOpen }"
        class="dropdown mega-menu d-none d-md-block"
        v-on-clickaway="closeMegaMenu"
      >
        <a
          href="#"
          class="btn text-muted dropdown-toggle mr-3"
          id="dropdownMegaMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          @click="toggleMegaMenu"
          >Panel Admin</a
        >
        <div
          class="dropdown-menu text-left"
          :class="{ show: isMegaMenuOpen }"
          aria-labelledby="dropdownMenuButton"
        >
          <div class="row m-0">
            <div class="col-md-4 p-4 text-left bg-img">
              <div style="background-color: #f8fafe; padding-top: 10px !important; padding-left: 5px; padding-bottom: 20px;">
                <h2 class="title" style="color:black;">
                  Panel Admin Facturador
                </h2>
                <p>
                  Las siguiente funciones son para administrar las
                  funciones y parametros
                </p>
              </div>
            </div>
            <div class="col-md-7 p-4 text-left">
              <div class="menu-icon-grid w-auto p-0">
                <router-link class="nav-item-hold" :to="{ name: 'dashboard-index' }"  @click.native="closeMegaMenu">
                <i class="i-Statistic"></i>
                <span class="nav-text">Dashboard</span>
                </router-link>
                <router-link class="nav-item-hold" to="/configuracion" @click.native="closeMegaMenu">
                  <i class="i-Gears"></i> 
                  <span class="nav-text">Configuracion</span>
                </router-link>
                <router-link class="nav-item-hold" :to="{ name: 'empresa-index'}" @click.native="closeMegaMenu">
                  <i class="i-Posterous"></i>
                  <span class="nav-text">Empresas</span>
                </router-link>
                <a href="#" @click="closeMegaMenu">
                  <i class="i-Letter-Open"></i> Registros 
                </a>
                <router-link class="nav-item-hold" to="/paquete" @click.native="closeMegaMenu">
                  <i class="i-Check"></i>
                  <span class="nav-text">Paquetes</span>
                </router-link>
                <router-link class="nav-item-hold" :to="{ name: 'usuario-configuracion-index'}" @click.native="closeMegaMenu">
                  <i class="i-Administrator"></i>
                  <span class="nav-text text-center">Usuario de Sistemas</span>
                </router-link>
              </div>
            </div>
            <div class="col-md-2 p-4 text-left">
            </div>
          </div>
        </div>
      </div>
      <!-- / Mega menu -->
      <!-- <div class="search-bar" @click="toggleSearch">
        <input type="text" placeholder="Search" />
        <i class="search-icon text-muted i-Magnifi-Glass1"></i>
      </div> -->
    </div>

    <div style="margin: auto"></div>
    <div class="header-part-right">
      <!-- Notificaiton -->
      <!-- Full screen toggle -->
      <i
        class="i-Full-Screen header-icon d-none d-sm-inline-block"
        @click="handleFullScreen"
      ></i>
      
      <!-- Notificaiton End -->
      <div class="dropdown">
        <b-dropdown
          id="dropdown-1"
          text="Dropdown Button"
          class="m-md-2 user col align-self-end"
          toggle-class="text-decoration-none"
          right
          no-caret
          variant="link"
        >
          <template slot="button-content">
            <img
              :src="getPathImgUser"
              id="userDropdown"
              alt=""
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            />
          </template>

          <div
            class="dropdown-menu-right"
            aria-labelledby="userDropdown"
          >
            <div class="dropdown-header">
              <i class="i-Lock-User mr-1"></i> {{ user.nombre }}
            </div>
            <b-dropdown-item-button disabled>
              ID:{{ company.numero }} {{ company.nombre_comercial }}
            </b-dropdown-item-button>
            <router-link
              :to="{ name: 'usuario-index'}"
              class="text-black ml-3"
              active-class="activo"
            >
            </router-link>
          </div>
        </b-dropdown>
      </div>
      <a href="#" class="text-dark mr-5" @click.prevent="logoutUser">
        <svg width="1.5em"
        height="1.5em"
        viewBox="0 0 16 16"
        class="bi bi-door-open"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M1 15.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0
            1-.5-.5zM11.5 2H11V1h.5A1.5 1.5 0 0 1 13 2.5V15h-1V2.5a.5.5 0 0 0-.5-.5z"/>
          <path fill-rule="evenodd" d="M10.828.122A.5.5 0 0 1 11 .5V15h-1V1.077l-6
            .857V15H3V1.5a.5.5 0 0 1 .43-.495l7-1a.5.5 0 0 1 .398.117z"/>
          <path d="M8 9c0 .552.224 1 .5 1s.5-.448.5-1-.224-1-.5-1-.5.448-.5 1z"/>
        </svg>
        <b class="pl-1">Salir</b>
      </a>
    </div>
  </div>

  <!-- header top menu end -->
</template>
<script>
import Util from '@/utils';
import { isMobile } from "mobile-device-detect";
import { mixin as clickaway } from 'vue-clickaway';
import { mapGetters, mapActions, mapState } from 'vuex';

export default {
  mixins: [clickaway],
  components: {
  },

  data() {
    return {
      isSearchOpen: false,
      isMouseOnMegaMenu: true,
      isMegaMenuOpen: false,
    };
  },
  mounted() {
    if (this.company.id == this.superAdmin) {
      this.sideBarToggle(false);
    }
  },
  computed: {
    ...mapGetters('main', [
      'getPathLogo',
      'getPathImgUser',
    ]),
    ...mapGetters(['getSideBarToggleProperties']),
    ...mapState('main', [
      'user',
      'company',
      'superAdmin'
    ]),
  },

  methods: {
    closeMegaMenu() {
      this.isMegaMenuOpen = false;
    },
    toggleMegaMenu() {
      this.isMegaMenuOpen = !this.isMegaMenuOpen;
    },
    toggleSearch() {
      this.isSearchOpen = !this.isSearchOpen;
    },
    ...mapActions([
      'changeSecondarySidebarProperties',
      'changeSidebarProperties',
      'signOut',
    ]),
    handleFullScreen() {
      Util.toggleFullScreen();
    },
    logoutUser() {
      this.signOut();
      this.$router.push('/login');
    },
    sideBarToggle() {
      if (
        this.getSideBarToggleProperties.isSideNavOpen &&
        this.getSideBarToggleProperties.isSecondarySideNavOpen &&
        isMobile
      ) {
        this.changeSidebarProperties();
        this.changeSecondarySidebarProperties();
      } else if (
        this.getSideBarToggleProperties.isSideNavOpen &&
        this.getSideBarToggleProperties.isSecondarySideNavOpen
      ) {
        this.changeSecondarySidebarProperties();
      } else if (this.getSideBarToggleProperties.isSideNavOpen) {
        this.changeSidebarProperties();
      } else if (
        !this.getSideBarToggleProperties.isSideNavOpen &&
        !this.getSideBarToggleProperties.isSecondarySideNavOpen &&
        !this.getSideBarToggleProperties.isActiveSecondarySideNav
      ) {
        this.changeSidebarProperties();
      } else if (
        !this.getSideBarToggleProperties.isSideNavOpen &&
        !this.getSideBarToggleProperties.isSecondarySideNavOpen
      ) {
        // console.log("4");

        this.changeSidebarProperties();
        this.changeSecondarySidebarProperties();
        // console.log("4");
      }
    }
  },
};
</script>

<style scoped>
.text-black{
  color:black;
}
.activo{
  color:#01a9db;
}
.mega-menu .dropdown-menu .bg-img {
  background: white;
  color: black;
}
</style>
