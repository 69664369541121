<template>
  <div class="app-admin-wrap layout-sidebar-large clearfix">
    <top-nav />
    <sidebar/>
    <main>
      <div 
      :class="{ 'sidenav-open': getSideBarToggleProperties.isSideNavOpen }"
      class="main-content-wrap d-flex flex-column">
        
        <transition name="page" mode="out-in">
          <router-view />
        </transition>
      </div>
    </main>
  </div>
</template>

<script>
import TopNav from "./TopNav.vue";
import sidebar from "./Sidebar.vue";
import { mapGetters, mapState } from "vuex";
import { util } from '@/plugins/util';

export default {
  components: {
    TopNav,
    sidebar,
  },
  data() {
    return {};
  },
  created() {
    window.Echo.channel('siat-notification.' + this.user.id).listen('.SiatNotification', (e) => {
      let data = e.data
      if (data) {
        if (data.error == 0) {
          util.showNotify(e.data.message, 'success');
        }
        if (data.error != 0) {
          util.showNotify(e.data.message, 'error');
        }
      }
    });
  },
  computed: {
    ...mapGetters(["getSideBarToggleProperties"]),
    ...mapState('main', [
      'user'
    ])
  },
  methods: {

  }
};
</script>
<style>
.page-item.active .page-link {
  z-index: 1;
  color: white;
  background-color: #00bd7e !important;
  border-color: #00bd7e !important;
}
.bg-custom-green{
  color: black;
  background-color: #00bd7e;
}
.custom-border-top{
  border-top: 1px solid #00bd7e !important;
}
.th-custom-color{
  background-color: #00bd7e;
  color: white;
}
.dropdown-item:active
{
  background-color: #00bd7e;
  color:black;
}

/* // tabs */
.nav-tabs .nav-item .nav-link.active {
  border: 1px solid transparent;
  background: rgba(102, 51, 153, 0.1);
  border-color: #00bd7e #00bd7e #fff;
}
.nav-tabs {
  border-bottom: 1px solid #00bd7e;
  justify-content: end;
}
.vue-notification-group {
  top: 35px !important;
}
.vue-notification > .notification-title {
  font-weight: bold !important;
}

</style>